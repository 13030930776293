/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"
import { IntlProvider } from 'react-intl'

import Header from "./header"
import Footer from "./footer"
import "../../static/styles/main.scss"

// Messages
import en from '../locales/en/messages.json'
import fi from '../locales/fi/messages.json'

const messages = { en, fi }

const Layout = ({ locale, signup, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Header siteTitle={'Title'} signup={signup}/>
        <main>{children}</main>
    <Footer />
  </IntlProvider>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout