module.exports = {
    en: {
      path: 'en',
      locale: 'English',
    },
    fi: {
      path: 'fi',
      locale: 'Suomi',
      default: true,
    }
  }