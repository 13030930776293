import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/localizedLink';

import logo from "../../static/images/pink-logo.png"

import locales from '../constants'

const Header = ({ intl: { locale }, siteTitle, signup }) => (
    <header>
        <div className={"container"}>
            <div className={"top-menu"}>
                <div className={"logo"}>
                    <LocalizedLink to="/" title={"Reko.ai"}>
                        <img alt={"Logo"} src={logo}/>
                    </LocalizedLink>
                </div>
                {!signup && <div className={"get-started"}>
                    <LocalizedLink to="/signup" title={"Reko.ai"}>
                        <FormattedMessage id="CallToActionButton" />
                    </LocalizedLink>
                </div>}
                <div className={"lang-select"}>
                    {/* {Object.keys(locales).map(key => (
                        <span className={key === locale ? 'active' : ''}>
                            <Link
                                key={locales[key].locale}
                                to={locales[key].default ? '/' : `/${locales[key].path}`}>
                                {locales[key].locale}
                            </Link>
                        </span>
                    ))} */}
                </div>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default injectIntl(Header)
