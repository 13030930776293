import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/localizedLink';

import logo from "../../static/images/reko2-logo.png"
import iconTwitter from "../../static/images/icon-twitter.svg"
import iconLinkedin from "../../static/images/icon-linkedin.svg"
import iconInstagram from "../../static/images/icon-instagram.svg"

class Footer extends Component {
    render() {
      return (
        <footer>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-3"}>
                        <div className={"widget__item"}>
                            <div className={"logo"}>
                            <LocalizedLink to="/" title={"Reko.ai"}>
                                <img alt={"Logo"} src={logo} style={{width: '80px'}} />
                            </LocalizedLink>
                            </div>
                            <div><small>Certified MyCashflow Partner</small></div>
                            <div className={"about"}>
                                <p><FormattedMessage id="ShortAbout" /></p>
                            </div>
                        </div>
                    </div>

                    <div className={"col-2"}>
                        <div className={"widget__item"}>
                            <ul className={"links"}>
                                <h4>Reko</h4>
                                <ul>
                                    <li><LocalizedLink to="/requirements" title={"About Us"}><FormattedMessage id="Requirements" /></LocalizedLink></li>
                                    {/* <li><LocalizedLink to="/integrations/ga" title={"Integrations"}><FormattedMessage id="Integrations" /></LocalizedLink></li> */}
                                    <li><LocalizedLink to="/signup" title={"Sign Up"}><FormattedMessage id="Sign up" /></LocalizedLink></li>
                                </ul>
                            </ul>
                        </div>
                    </div>

                    <div className={"col-2"}>
                        <div className={"widget__item"}>
                            <div className={"links"}>
                                <h4><FormattedMessage id="Support" /></h4>
                                <ul>
                                    <li><LocalizedLink to="/contact" title={"Contact Us"}><FormattedMessage id="Contact" /></LocalizedLink></li>
                                    <li><LocalizedLink to="/privacy" title={"Privacy Policy"}><FormattedMessage id="Privacy" /></LocalizedLink></li>
                                    {/* <li><LocalizedLink to="/terms" title={"Terms Of Use"}><FormattedMessage id="Terms" /></LocalizedLink></li> */}
                                    {/* <li><LocalizedLink to="/terms" title={"Terms Of Use"}><FormattedMessage id="Terms" /></LocalizedLink></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className={"col-4"}>
                        <div className={"widget__item"}>
                            <div className={"links"}>
                                {/* <h4><FormattedMessage id="Blog" /></h4> */}
                                <ul>
                                    {/* <li><LocalizedLink to="/blog/22-01-2020/suosittelujarjestelmat" title={"Suosittelujärjestelmät"}><FormattedMessage id="Suosittelujärjestelmät" /></LocalizedLink></li> */}
                                    {/* <li><LocalizedLink to="/blog/20-01-2020/konversioprosentin-kasvattaminen" title={"Konversion kasvattaminen"}><FormattedMessage id="Konversioprosentin kasvattaminen" /></LocalizedLink></li> */}
                                    {/* <li><LocalizedLink to="/blog/19-01-2020/implisiittinen-eksplisiittinen-data" title={"Implisiittinen ja eksplisiittinen data"}><FormattedMessage id="Implisiittinen ja eksplisiittinen data" /></LocalizedLink></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={"col-1"}>
                        <div className={"widget__item"}>
                            <div className={"social"}>
                                {/* <a href="https://twitter.com/rekoai" target={"_blank"} title={"Twitter"}><img alt={"Twitter"} src={iconTwitter}/></a> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"copyright"}>
                    <p>{new Date().getFullYear()} {` `} <LocalizedLink to="/" title={"Reko.ai"}>Reko.ai</LocalizedLink></p>
                </div>
            </div>
        </footer>
      )
    }
  }

  export default Footer